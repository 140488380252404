import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import Slider from "../components/slider_work"
import Seo from "../components/seo"


    
const replaceValidation = e =>{
    let val = e.target.value
    //全角を半角に修正
    function han2Zen(str) {
        return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
    }
    let replaceVal = han2Zen(val);
    e.target.value = replaceVal
    //バリデーション
    Validation(e)
}

let validFlug = [0,0,0,0,0,0,0]
const Validation = e =>{
    let validationMessage = e.target.validationMessage
    let formName = e.target.name
    
    var i
    if(formName==="company"){
        i = 0
    }else if(formName==="department"){
        i = 1
    }else if(formName==="occupation"){
        i = 2
    }else if(formName==="position"){
        i = 3
    }else if(formName==="name"){
        i = 4
    }else if(formName==="mail"){
        i = 5
    }else if(formName==="tel"){
        i = 6
    }else if(formName==="text"){
        i = 7
    }
    if(validationMessage === ""){
        e.target.style.background="whitesmoke"
        validFlug[i] = 1
    }else{
        e.target.style.background="pink"
        validFlug[i] = 0
    }
    //送信ボタン
    let submitFlug = [1,1,1,1,1,1,1]
    let submit = document.getElementById("submit")
    if(validFlug.toString() === submitFlug.toString()){
        submit.style.filter="none"
        submit.style.pointerEvents="auto"
    }else{
        submit.style.filter="grayscale(100%)"
        submit.style.pointerEvents="none"
    }
}

const kumikomiHtml = ({data,location}) => (
	<Layout>
	  <Seo
		pagetitle="製品強化"
		pagedesc="装置メーカー様向けAI組込ソリューション"
		pagepath={location.pathname}
	  />
<div className="container-fluid kumikomi product-detail_2">
  <div className="row">
      <div className="top-view">
        <div className="top-img bg-img_cover" />
        <ul className="breadcrumb">
          <li><Link to={`/`}>TOP</Link></li>
          <li><Link to={`/product/`}>PRODUCT</Link></li>
          <li>製品強化</li>
        </ul>{/*.breadcrumb*/}
        <div className="h1_wrap">
          <h1>製品強化</h1>
        </div>{/*.h1_wrap*/}
        <div className="h1-lead">装置メーカー様向けAI組込ソリューション</div>
        <a href="#scroll">
          <div className="scroll-down" id="scroll-button">
            <div>SCROLL</div><div />
          </div>
        </a>
      </div>{/*.top-view*/}
      <div id="scroll" className="solution slide_1 bg-img_cover black-filter">
        <div className="contents_wrap">
          <div className="product-title">
            <div>SOLUTION</div><div>相談・初期費用無料</div>
          </div>{/*.product-title*/}
          <h2 className="product-h2 underline">装置メーカー様向け<br />AI組込ソリューション</h2><div />
          <div className="product-lead">
            工作機械・加工機械・半導体製造装置・食品機械・包装機械・建設機械など、お客様の自社製品を初期費用無料でAI化し、現代ニーズに応えられる製品価値の強化を図ります。
          </div>{/*.product-lead*/}
        </div>{/*.contents_wrap*/}
      </div>{/*.solution*/}
        <div className="future">
          <div className="bk-back">
            <div className="future-img bg-img" />
          </div>
          <div className="contents-box">
            <div className="contents-title">FUTURE</div>
            <h3 className="underline">リスクフリーでAI化</h3><div />
            <div className="contents-comment">
              <div className="comment-br">
                本サービスは、「自社製品にAIを搭載したいけど、人材がいなくて設計や開発が行えない」・「外注は初期費用が大きすぎて頼めない」といった課題に対し、お客様の開発チームの一員として設計から開発まで徹底的に伴走しワンストップで解決させていただきます。
              </div>
              <div className="comment-br">
                また、MAZINでは開発したAI搭載装置の販売台数に応じて料金をいただく独自のモデルを採用しているので、AI開発にかかる莫大な初期費用をなくして自社製品のAI化へ繋がります。
              </div>
            </div>
          </div>{/*.contents-box*/}
        </div>{/*.future*/}
        <div className="sample">
          <div className="bk-back d-block d-md-none"><div className="system-img bg-img" /></div>
          <div className="contents-box">
            <div className="contents-title">SAMPLE</div>
            <h3 className="underline">より親しめる製品へ</h3><div />
            <div className="contents-comment">
              <div className="comment-br">
                製品を綺麗に包装するために、包装する製品の形状や材質、包装に使用する紙の材質など、様々な条件を考慮して機械の設定値を決める必要があり、エンドユーザー様が設定値を出すのに苦労されているという課題をお持ちの包装機械メーカー様の事例です。
              </div>
              <div className="comment-br">
                製品形状などの条件と装置の最適な設定値の関係を自動学習するAI組込ソリューションを導入することで、エンドユーザー様がより使いやすく、より親しめる製品へとアップグレードしています。
              </div>
            </div>
          </div>{/*.contents-box*/}
          <div className="bk-back d-none d-md-inline-block"><div className="system-img bg-img" /></div>
        </div>{/*.sample*/}
      <div className="">
        <div className="flow">
          <div className="contents-title">FLOW</div>
          <div className="contents-box">
            <h3 className="underline">ソリューションの流れ</h3><div />
            <div className="contents-comment">
              下記①～⑥まで、費用は一切発生いたしません。<br />
              「開発した製品が売れないと、弊社も売上が立たない」という構造上、お客様以上に真剣に伴走させていただきます。まずはどうぞお気軽にお問い合わせください。
            </div>
            <ul className="flow-list">
              <li><div>一</div><div>お問い合せ</div></li>
              <li><div>二</div><div>ヒアリング</div></li>
              <li><div>三</div><div>ご提案</div></li>
              <li><div>四</div><div>ご契約</div></li>
              <li><div>五</div><div>要件定義・設計</div></li>
              <li><div>六</div><div>開発・テスト</div></li>
              <li><div>七</div><div>販売開始</div></li>
              <li><div>八</div><div>機能改善</div></li>
            </ul>
          </div>{/*.contents-box*/}
        </div>{/*.flow*/}
        <div className="work wide-slider">
          <div className="contents-title">WORK</div>
          <div className="contents-box">
            <Slider />
          </div>{/*.contents-box*/}
        </div>{/*.work*/}
        <div className="download">
          <div className="contents-title">DOWNLOAD</div>
          <div className="contents-box">
            <h3 className="underline">製品カタログはこちら</h3><div />
            <div className="contents-comment">
              費用や詳細についてご興味のある方は、下記必要事項をご記入の上、こちらから製品カタログのダウンロードをお願いいたします。<br />
              ご相談・お問い合せは、コンタクトフォームよりどうぞお気軽にご連絡ください。
              <div className="small">＊入力は全て必須項目となっております</div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-5 col-xl-6"><div className="dl-img bg-img" /></div>
              <div className="col-12 col-lg-7 col-xl-6">
                <form name="KUMIKOMI-DL" method="POST" data-netlify="true" action={`/kumikomi_dl/`}>
                    <table>
                      <tbody>
                        <input type="hidden" name="form-name" value="SESSAKU-DL" />
                        <tr><th>会社名</th>
                            <td>
                                <input type="text" name="company" required
                                    placeholder="ZZZ株式会社"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>部署名</th>
                            <td>
                                <input type="text" name="department" required
                                    placeholder="ZZZ部"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>職種</th>
                            <td>
                                <input type="text" name="occupation" required
                                    placeholder="ZZZ" 
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>役職</th>
                            <td>
                                <input type="text" name="position" required
                                    placeholder="ZZZ"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>氏名</th>
                            <td>
                                <input type="text" name="name" required
                                    placeholder="ZZ ZZZ"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th className="line-2" aria-label="勤務先メールアドレス"><div><span>勤務先</span><span>メールアドレス</span></div></th>
                            <td>
                                <input type="email" name="mail" required
                                    placeholder="ZZZ@mazin.tech" 
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>電話番号(携帯)</th>
                            <td>
                                <input type="tel" name="tel" required
                                    placeholder="00-0000-0000"
                                    onBlur={replaceValidation}
                                    onInput={replaceValidation}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>IoT導入予定時期</th>
                            <td>
                                <select name="timing" required>
                                  <option value="3ヶ月以内">3ヶ月以内</option>
                                  <option value="半年以内">半年以内</option>
                                  <option value="1年以内">1年以内</option>
                                  <option value="1年以上先">1年以上先</option>
                                  <option value="時期は未定だが、検討中">時期は未定だが、検討中</option>
                                  <option value="予定はない">予定はない</option>
                                </select>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  <div className="submit_wrap">
                    <button type="submit" id="submit">ダウンロード</button>
                    <div className="small">このサイトはreCAPTCHAによって保護されており、<br />Googleのプライバシーポリシーと利用規約が適用されます。</div>
                　　</div>
                </form>
              </div>
            </div>
          </div>{/*.contents-box*/}
        </div>{/*.download*/}
      </div>{/**/}
  </div>{/*.row*/}
</div>{/*.container-fluid*/}

	
	</Layout>

)

export default kumikomiHtml